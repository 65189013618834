import { DocumentCheckIcon } from '@heroicons/react/24/outline';
import { Fragment, h } from 'preact'; /** @jsx h */
import { useEffect, useState } from 'preact/hooks';
import EditForm from './EditForm.js';
import { getCheckedItems, mapItemList } from './tableFunctions';
import { datoToText, timeTypePromise } from './utils.js';

const Table = (props) => {
  // Hente time type fra API
  const [timeType, setTimeType] = useState([]);
  useEffect(async () => {
    const a = await timeTypePromise();

    setTimeType(a.timetype.slice(0, 5));
  }, []);

  // For total time hver person
  const total = timeType.map((x) => ({
    time: 0,
    lkode: x.lkode,
    order: x.order
  }));

  const {
    type,
    dato,
    tdata,
    dagTime,
    isPersonTable,
    person,
    isGodkjentAvLeder,
    vakttyper
  } = props;
  const stat = Number(tdata[0].stat);

  const [checkedItem, setCheckedItem] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [itemVakttype, setItemVakttype] = useState(0);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setItemList(tdata);
  }, [tdata]);

  const handleClick = (e) => {
    const { name, checked } = e.target;

    const newList = mapItemList(itemList, name, checked, type, isPersonTable);
    setItemList(newList);

    const sjekk = getCheckedItems(newList, type, isPersonTable);
    setCheckedItem(sjekk);

    setCheckedAll(sjekk.length === newList.length);
  };

  const handleEdit = (e) => {
    if (
      type === 'tilLeder' ||
      type === 'lederRediger' ||
      type === 'godkjent_tm'
    ) {
      const idDato = e.target.value.split('_');
      const itemEdit = dagTime.filter(
        (x) => x.usid === Number(idDato[0]) && x.dato === idDato[1]
      );
      setItemData(itemEdit);
    } else {
      isPersonTable
        ? setItemData(dagTime.filter((x) => x.dato === e.target.value))
        : setItemData(dagTime.filter((x) => x.usid === Number(e.target.value)));

      const vakttype = tdata.find(
        (x) => x.usid === Number(e.target.value)
      ).vakttype;
      setItemVakttype(vakttype);
    }
    setShowEdit(true);
  };

  const handleCancel = (e) => {
    setShowEdit(false);
  };

  const onSubmit = async () => {
    let data = {};
    let action = 'godkjtm';

    if (type === 'tilLeder') {
      data = {
        data: checkedItem
      };
      action = 'godkjld';
    } else {
      data = {
        dato,
        user: checkedItem
      };

      if (isPersonTable) {
        data = {
          dato: checkedItem,
          user: person
        };
      }
    }

    const resp = await fetch('/api/admTimereg.php?action=' + action, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const json = await resp.json();

    if (json.success) {
      window.location.reload();
    } else {
      // console.log(json.error_msg);
    }
  };

  return (
    <Fragment>
      <table className="pa3 w-100 td-l1 mb3-halv" id="timer-liste">
        <thead className="bt b--black">
          <tr>
            {(isPersonTable || type === 'tilLeder' || isGodkjentAvLeder) && (
              <th>Dato</th>
            )}
            {!isPersonTable && <th>Navn</th>}
            {!isPersonTable && <th className="table-text-center">Type</th>}
            {timeType.map((tT, index) => (
              <th className="tr" key={index}>
                {tT.beskrivelse}
              </th>
            ))}
            <th className="tr">Sum</th>
            {(type === 'vent' || stat === 20 || type === 'lederRediger') && (
              <th className="tc">Rediger</th>
            )}
            <th className="tc">
              {type === 'vent' || type === 'tilLeder' ? (
                <input
                  type="checkbox"
                  className="checkmark godkjenn-alt"
                  name="all"
                  onClick={handleClick}
                  value=""
                  checked={checkedAll ? 'checked' : ''}
                />
              ) : (
                <DocumentCheckIcon className="godkjenn-alt godkjent-alt-doc" />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {itemList.length > 0 &&
            itemList.map((item) => {
              const usid = item.usid;
              const navn = item.navn;
              const dato = item.dato;

              const vakttypeObj =
                vakttyper &&
                vakttyper.find((x) => parseInt(x.vakt_type) === item.vakttype);
              const vakttype = vakttypeObj ? vakttypeObj.vakt_navn : 'UV';

              const tempArr = dagTime.filter(
                (x) => x.usid === usid && x.dato === dato && x.lkode < 30
              );

              if (typeof tempArr === 'undefined' || tempArr.length === 0) {
                return <tr key={usid}></tr>;
              } else {
                // Dags sum timer
                let sum = 0;

                let key = 0;
                if (
                  type === 'tilLeder' ||
                  type === 'lederRediger' ||
                  type === 'godkjent_tm'
                ) {
                  key = `${usid}_${dato}`;
                } else {
                  // Kan være usid, eller dato
                  key = isPersonTable ? dato : usid;
                }

                // empty timeData
                const timeData = timeType.map((x) => ({
                  time: 0,
                  farge: '',
                  lkode: x.lkode,
                  order: x.order
                }));
                let beskriv = '';
                tempArr.forEach((element) => {
                  const lkode = element.lkode;
                  const time = element.time;
                  const farge = element.farge;
                  if (element.beskriv.length > 0) {
                    beskriv = element.beskriv;
                  }
                  const index = timeData.findIndex((x) => x.lkode === lkode);
                  if (index >= 0) {
                    timeData[index].time = time;
                    timeData[index].farge = farge;

                    total[index].time += time;
                  }

                  sum += time;
                });

                return (
                  <tr className="hasTooltip" key={usid}>
                    {(isPersonTable ||
                      type === 'tilLeder' ||
                      isGodkjentAvLeder) && (
                      <td className="tr">
                        <b>{datoToText(dato).toLocaleLowerCase()}</b>
                      </td>
                    )}
                    {!isPersonTable && (
                      <td>
                        {/* <img
                          className="img-20 avatar"
                          src={`/images/brukere/${usid}.jpg`}
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/images/placeholder.png';
                          }}
                        /> */}
                        {navn}

                        {beskriv && (
                          <Fragment>
                            💬
                            <span className="hasTooltip">
                              {navn}: {beskriv}
                            </span>
                          </Fragment>
                        )}
                      </td>
                    )}
                    {!isPersonTable && (
                      <td className="table-text-center">{vakttype}</td>
                    )}
                    {timeData.map((value, index) => (
                      <td className={`tr ${value.farge}`} key={index}>
                        {value.time.toFixed(2)}
                      </td>
                    ))}
                    <td className="tr">{sum.toFixed(2)}</td>
                    {(type === 'vent' ||
                      stat === 20 ||
                      type === 'lederRediger') && (
                      <td className="tc">
                        <button
                          value={key}
                          onClick={handleEdit}
                          disabled={item.stat >= 50}
                        >
                          Rediger
                        </button>
                      </td>
                    )}
                    <td className="tc">
                      {type === 'vent' || type === 'tilLeder' ? (
                        <input
                          type="checkbox"
                          className="checkmark"
                          name={key}
                          onClick={handleClick}
                          checked={item.isChecked ? 'checked' : ''}
                        />
                      ) : (
                        'Ja'
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          {isPersonTable && (
            <tr>
              <td className="tr">Totalt</td>
              {total.map((value, index) => (
                <td className="tr" key={index}>
                  {value.time.toFixed(2)}
                </td>
              ))}
              <td className="tr">
                {total.length > 0 &&
                  total
                    .reduce((a, b) => {
                      return a + b.time;
                    }, 0)
                    .toFixed(2)}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {checkedItem.length > 0 && (
        <button
          className="mt3 mb3 pa2 ph3 bg-primary white db"
          onClick={onSubmit}
        >
          Sett til godkjent
        </button>
      )}

      {showEdit && (
        <EditForm
          itemData={itemData}
          vakttype={itemVakttype}
          handleCancel={handleCancel}
        ></EditForm>
      )}
    </Fragment>
  );
};

export default Table;
